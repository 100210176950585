import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
  }

  toggle(event) {
    var faqBody = event.currentTarget.parentElement.querySelector('[data-target~=accordion-body]');
    var faqHeader = event.currentTarget.parentElement.querySelector('[data-target~=accordion-header]');
    if (faqBody.style['display'] != 'none') {
      var slideDir = 'slideUp';
      faqHeader.classList.remove("expanded");
    } else {
      var slideDir = 'slideDown';
      faqHeader.classList.add("expanded");
    }
    Velocity(faqBody, slideDir, {duration: 400, easing: 'ease-in-out' })
  }
}
