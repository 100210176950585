import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "burger", "navigation" ]

  close(event) {
    event.preventDefault();
    this.navigationTarget.style.height = "0"
  }

  open(event) {
    event.preventDefault();
    this.navigationTarget.style.height = "100%"
  }
}
