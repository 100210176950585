// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()

// Stylesheets
require('normalize.css/normalize.css');
require('stylesheets/application.scss');

// Javascript
require('velocity-animate');
require('./fontawesome.js');

import "controllers"
