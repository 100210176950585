import { Controller } from "stimulus"
import 'lightgallery.js/dist/css/lightgallery.css'
import 'lightgallery.js/dist/js/lightgallery.min.js'


export default class extends Controller {
  static targets = [ "gallery" ]

  connect() {
    lightGallery(this.galleryTarget);
  }
}
