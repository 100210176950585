import { config, library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faChevronLeft, faChevronRight, faChevronDown, faChevronUp,
  faBars, faHome, faBell, faFileSignature, faClipboardList, faUserNinja, faUsers,
  faInbox, faClock, faCheck, faMobileAlt, faBroom, faTshirt, faSignature,
  faMapPin, faCalendarAlt, faPlus, faEllipsisH, faTimes, faPencilAlt,
  faTrash, faRetweet, faPlay, faStop, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

import {
  faInstagram, faFacebook
} from '@fortawesome/free-brands-svg-icons'

library.add (
  faChevronLeft, faChevronRight, faChevronDown, faChevronUp,
  faBars, faHome, faBell, faFileSignature, faClipboardList, faUserNinja, faUsers,
  faInbox, faClock, faCheck, faMobileAlt, faBroom, faTshirt, faSignature,
  faMapPin, faCalendarAlt, faPlus, faEllipsisH, faTimes, faPencilAlt,
  faTrash, faRetweet, faPlay, faStop, faExclamationTriangle,
  faFacebook, faInstagram, faRetweet
)

config.mutateApproach = 'sync'
dom.watch()

